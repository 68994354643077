//
// how-to.scss
//

.how-to-avatar {
  img {
    display: inline-flex;
    max-width: 70px;
    margin: 1rem;
  }
}