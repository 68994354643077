//
// blog.scss
//

.blog-box {
    .img-text-wrapper {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        color: black;
        font-weight: 400;
    }
    .subtitle {
        transition: 1s ease-in-out;
        color: black;
    }
    .blog-img {
        max-width: 150px;
    }
    .blog-box-content {
        margin-bottom: 30px;
    }
}